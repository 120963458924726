
import axios from "axios"
import React, {useState} from "react"
import AllTasks from "../AllTasks"
import Moment from 'moment';
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import NumberFormat from "react-number-format";

const ModalTicket = ({showModal,setShowModal, ticketInModal}) => {

    const ticket = ticketInModal
    const [poste,setPoste] = useState('Gestion de projet')
    const [madeBy,setMadeBy] = useState()
    const [cost,setCost] = useState()
    const [NomTicket,setNomTicket] = useState(ticket.NameTicket)
    const [Description,setDescription] = useState(ticket.Description)
    const [Date,setDate] = useState(Moment(ticket.CreatedAt).format('YYYY-MM-DD'))
    


    const [updating,setupdating] = useState(false)
    //const [modeCout,setmodeCout] = useState(localStorage.getItem('modeCout') ? localStorage.getItem('modeCout') : false )
    const [modeCout,setmodeCout] = useState(false)
    const [suggestions,setSuggestions] = useState([])



    const [modificationNomTicket, setmodificationNomTicket] = useState(false)
    const [nomTicket, setnomTicket] = useState(ticket.NameTicket)
    

    const [modificationDescription, setmodificationDescription] = useState(false)
    const [descriptionTicket, setDescriptionTicket] = useState(ticket.Description)


    const showHideClasseName = showModal ? "modal open" : "modal close";

    const {idProjet} = useParams();
    const history = useHistory()

 



    const handleSumbit = (e) =>{
        e.preventDefault()

        console.log(ticket)
        
        let formData = new FormData()
        formData.append('Category',poste)
        formData.append('made_by',madeBy)

        if(!modeCout){
            formData.append('cost_task',cost*4)
        }else{
            formData.append('cost_task',cost)
        }
        formData.append('fk_idTicket',ticket.idTicket)
        formData.append('fk_idProjet',ticket.idProjet)

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL+"task",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          }).then((res) => {
            
            /*if(res.data.state == 'success'){
                setSuccess(true)
                reset()
                setTimeout(function(){
                    setShowModal(false)
                },1000)
            }*/
            
          })
          setCost('')
          setMadeBy('')
          setPoste('Gestion de projet')

    }

    const handleSumbitChangeTicket = (e) =>{
        e.preventDefault()
     
        let qs = 'idTicket='+ticket.idTicket+'&NameTicket='+NomTicket+'&Description='+Description+'&CreatedAt='+Date
        axios({
                method: "put",
                url: process.env.REACT_APP_API_URL+"ticket",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs
        }).then(()=>{
            setupdating(false)
            console.log('Udapting',updating)
        })

        
        

    }


    const deleteTicket= () => {

        let qs = 'idTicket='+ticket.idTicket
        axios({
            method: "delete",
            url: process.env.REACT_APP_API_URL+"ticket",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs,
          }).then((res)=>{
            if(res.data){
                setShowModal(false)
                history.push(`/projets/${idProjet}`)
            }
          })
        
    }

    const closeModalTicket = () => {
        setShowModal(false)
        history.push(`/projets/${idProjet}`)
        
    }

    const autoCompleteMadeBy = (madeByInput) =>{
        setMadeBy(madeByInput)
        

        let formData = new FormData()
        formData.append('poste',poste)
        formData.append('madeby',madeByInput)

      

        
        if(madeByInput.length>=3){
            axios.defaults.headers.common['Authorization']  = 'Bearer '+localStorage.getItem('token')
            
            axios.get(process.env.REACT_APP_API_URL+'task/'+poste+'/'+madeByInput).then((res) => {
                if(res.data){
                    setSuggestions(res.data) 
                }
            })
        }else{
            setSuggestions([])
        }

        

    }

    const changeNomTicket = (e) => {
        e.preventDefault()
        setmodificationNomTicket(false)
        let qs = 'idTicket='+ticket.idTicket+'&NameTicket='+nomTicket
        axios({
                method: "put",
                url: process.env.REACT_APP_API_URL+"ticket",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs
        })

    }

    const changeDescriptionTicket = (e) => {
        e.preventDefault()
        setmodificationDescription(false)
        let qs = 'idTicket='+ticket.idTicket+'&Description='+descriptionTicket
        axios({
                method: "put",
                url: process.env.REACT_APP_API_URL+"ticket",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs
        })

    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            changeDescriptionTicket(event)
        }
    }

    return (
        <div className={showHideClasseName}>
        <div className="modal-container">
        
        <div className="modal-header-ticket">

            <div className="title-delete">

            <h1>{nomTicket}</h1>
                {/* {!modificationNomTicket && <h1 onClick={()=>{setmodificationNomTicket(true)}}>{nomTicket}</h1>}
                {modificationNomTicket && 
                <form className="modificationNomTicket" onSubmit={(e)=>{changeNomTicket(e)}}>
                    <input type="text" value={nomTicket} onChange={(e)=>{setnomTicket(e.target.value)}}/> 
                </form>
                } */}

                <div className="button delete" onClick={(e) => {
                    if(window.confirm(`Êtes-vous sur de vouloir supprimer le ticket ${ticket.NameTicket} ?`)){
                        deleteTicket()
                    }
                }}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" className="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>
                </div>

                <div className="button options" onClick={(() => setupdating(true))}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cog" className="svg-inline--fa fa-cog fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"></path></svg>
                </div>
            </div>

            <p className="createdAt">{Moment(ticket.CreatedAt).format('L')}</p>

            <div className="content-modif-description">
            <p>{descriptionTicket}</p>
                {/* {!modificationDescription && <p onClick={(e)=>{setmodificationDescription(true)}}>{descriptionTicket}</p> }
                {modificationDescription &&    
                    <form className="modificationDescriptionTicket" onSubmit={(e)=>{changeDescriptionTicket(e)}}>
                        <textarea value={descriptionTicket} onKeyPress={(e)=>{handleKeyPress(e)}}onChange={(e)=>{setDescriptionTicket(e.target.value)}}></textarea>
                    </form>
                } */}
            </div>

            {ticket.tasks.length > 0 && 
            <>
                <div className="infos">
                    <p className="cout cout-euro"><span><NumberFormat value={ticket.coutTicket*ticket.PriceTicket} displayType="text" thousandSeparator={' '} suffix="€"/></span></p>
                    <p className="cout cout-ticket"><span>{ticket.coutTicket}</span>tickets</p>
                    <p className="cout cout-temps"><span>{ticket.coutTicket/4}</span>heures</p>
                </div>
            </>
            }
 

            <button type="button" className="close" onClick={()=>{closeModalTicket()}}>
                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.22566 4.81096C5.83514 4.42044 5.20197 4.42044 4.81145 4.81096C4.42092 5.20148 4.42092 5.83465 4.81145 6.22517L10.5862 11.9999L4.81151 17.7746C4.42098 18.1651 4.42098 18.7983 4.81151 19.1888C5.20203 19.5793 5.8352 19.5793 6.22572 19.1888L12.0004 13.4141L17.7751 19.1888C18.1656 19.5793 18.7988 19.5793 19.1893 19.1888C19.5798 18.7983 19.5798 18.1651 19.1893 17.7746L13.4146 11.9999L19.1893 6.22517C19.5799 5.83465 19.5799 5.20148 19.1893 4.81096C18.7988 4.42044 18.1657 4.42044 17.7751 4.81096L12.0004 10.5857L6.22566 4.81096Z" fill="black"/>
                </svg>
            </button>
        </div>


        <div className="modal-content">

            {!updating && ticket.Demandeur && <p className="demandeur">Demandeur : {ticket.Demandeur}</p> }
            {updating &&
            <>
                <form onSubmit={(e) => handleSumbitChangeTicket(e)}>
                    <div>
                        <input type="text" value={NomTicket} onChange={(e) => setNomTicket(e.target.value)} placeholder="Nom du ticket"></input>
                    </div>
                    <div>
                        <textarea value={Description} placeholder="Description du ticket" onChange={(e) => setDescription(e.target.value)}></textarea>
                    </div>
                    <div>
                            <input type="date" name="date" value={Date} onChange={(e) => setDate(e.target.value)} />
                    </div>
                    <div className="submit-button">
                        <button >Valider les modifcations</button>
                    </div>
                </form>

            </>
            }

            <div className="task-guide">
                
                <div>
                    <strong>
                        Type
                    </strong>
                </div>
                <div>
                    <strong>
                        Collaborateur
                    </strong>
                </div>
                <div>
                    <strong>
                        Temps
                    </strong>
                </div>

                <div className="empty"></div>
          
             </div>

            {ticket.tasks &&  <AllTasks tasks={ticket.tasks} modeCout={modeCout} />}

            <hr></hr>

            <form className="ajout-task" autocomplete="off" onSubmit={(e) => handleSumbit(e)}>
                <div className="content-form-ajout-task">
                    <div>
                        <select value={poste} name="Poste" onChange={(e) => setPoste(e.target.value)}>
                            <option value="Gestion de projet" selected>Gestion de projet</option>
                            <option value="Directeur Web">Directeur Web</option>
                            <option value="Directeur conseil">Directeur conseil</option>
                            <option value="Directeur clientèle">Directeur clientèle</option>
                            <option value="Développeur">Développeur</option>
                            <option value="Test et saisie">Test et saisie</option>
                            <option value="Graphiste">Graphiste</option>
                            <option value="Traffic Manager">Traffic Manager</option>
                            <option value="Concepteur-rédacteur">Concepteur-rédacteur</option>
                            <option value="Autre">Autre</option>
                        </select>
                    </div>
                    <div className="made-by">
                        
                        <input type="text" value={madeBy} autocomplete="off" className={suggestions.length>0 ? 'has-suggestions' : ''} onChange={(e) => autoCompleteMadeBy(e.target.value)} placeholder="Fait par .."></input>
                        {suggestions.length>0 && <>
                        
                        <div className="suggestions">
                            {suggestions.map((suggestion,key)=>(
                            <div className="suggestion" key={key}>
                                <p onClick={()=>{
                                    setMadeBy(suggestion.madeByTask)
                                    setSuggestions([])
                                }}>{suggestion.madeByTask}</p>
                            </div>
                            ))}
                        
                        </div>
                        </>}

                    </div>
                    <div className="input-number">
                        <input type="number" value={cost} min="0" onChange={(e) => setCost(e.target.value)} placeholder="Nbr .." step={modeCout ? 1 : 0.25}/>
                        {modeCout && <p>tickets</p>}
                        {!modeCout && <p>heures</p>}
                        <div className="select-mode">
                            <svg onClick={(()=>{
                                localStorage.setItem('modeCout',false)
                                setmodeCout(false)
                                })}  aria-hidden="true" focusable="false" data-prefix="far" className={!modeCout && 'active'} data-icon="clock" className="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>
                            <span>|</span>
                            <svg onClick={(()=>{
                                localStorage.setItem('modeCout',true)
                                setmodeCout(true)})} aria-hidden="true" focusable="false" data-prefix="fas" className={modeCout && 'active'}  data-icon="ticket-alt" className="svg-inline--fa fa-ticket-alt fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M128 160h320v192H128V160zm400 96c0 26.51 21.49 48 48 48v96c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48v-96c26.51 0 48-21.49 48-48s-21.49-48-48-48v-96c0-26.51 21.49-48 48-48h480c26.51 0 48 21.49 48 48v96c-26.51 0-48 21.49-48 48zm-48-104c0-13.255-10.745-24-24-24H120c-13.255 0-24 10.745-24 24v208c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V152z"></path></svg>
                        </div>
                    </div>
                </div>
                <div className="submit-button">
                    <button>Ajouter</button>
                    <button class="terminer"
                     onClick={(e)=>{
                         closeModalTicket()
                         handleSumbit(e)
                         }}>Terminer</button>
                </div>
            </form>

        </div>
        </div>
    </div>
    )


}
export default ModalTicket;